import _ from '../misc/lodash';

const REGEXPS = {
  phoneNumber: /^[+]?[(]?[0-9]{3}[)]?[-s.]?[0-9]{3}[-s.]?[0-9]{4,6}$/,
  emailAddress: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
};

const validates = {
  // Todo improve errors "form.invalidValue"
  above: {
    test: (value, test) => _.validate('above', test, value),
    error: 'form.invalidValue',
  },
  least: {
    test: (value, test) => _.validate('least', test, value),
    error: 'form.invalidValue',
  },
  below: {
    test: (value, test) => _.validate('below', test, value),
    error: 'form.invalidValue',
  },
  most: {
    test: (value, test) => _.validate('most', test, value),
    error: 'form.invalidValue',
  },
  equal: {
    test: (value, test) => _.validate('deepEqual', test, value),
    error: 'form.invalidValue',
  },
  regExp: {
    test: (value, test) => _.validate('regExp', test, value),
    error: 'form.invalidFormat',
  },
  phoneNumber: {
    test: (value) => _.validate('regExp', REGEXPS.phoneNumber, value),
    error: 'form.errorPhoneNumber',
  },
  email: {
    test: (value) => _.validate('regExp', REGEXPS.emailAddress, value),
    error: 'form.errorEmail',
  },
};

const validatesRules = Object.keys(validates);

/**
 * Base Mixin for form text inputs
 */
export default {
  props: {
    groupId: {
      type: String,
    },
    label: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    initial: {
      type: [String, Array],
      default: '',
    },
    default: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'accent',
    },
    icon: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    noEdit: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Object,
      default: () => ({}),
    },
  },
  data: () => ({
    value: '',
  }),
  computed: {
    rules() {
      const rules = [];

      if (this.options && this.options.rules) {
        this.options.rules.forEach((rule) => {
          // Fallback legacy rules only support regex rules
          if (typeof rule.pattern !== 'undefined') {
            rule.type = 'regExp';
            rule.value = rule.pattern;
          }

          if (validatesRules.includes(rule.type)) {
            const validate = validates[rule.type];
            rules.push((value) => (
              // If input are not required validate only if is not empty
              (this.required === false && value.length === 0)
              || validate.test(value, rule.value) || this.$t(rule.error || validate.error)
            ));
          }
        });
      }

      if (this.required) {
        rules.push((value) => (/.+/.test(value) || this.$t('misc.required')));
      }
      return rules;
    },
    fieldReadonly() {
      if (this.noEdit === true && this.groupId !== 'new') {
        return true;
      }
      return this.readonly;
    },
  },
  watch: {
    value(value) {
      this.$emit('change', value);
    },
  },
  created() {
    let value = this.initial;
    if (!_.isEmpty(this.$attrs.value)) {
      value = this.$attrs.value;
    }
    if (_.isEmpty(value) && !_.isEmpty(this.default)) {
      value = this.default;
    }
    this.value = value;
  },
};
