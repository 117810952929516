<template>
  <v-select
    v-model="value"
    :items="items"
    :menu-props="{ maxHeight: '400' }"
    :label="`${$t(label)}${(required) ? ' *' : ''}`"
    :rules="rules"
    :color="color"
    :prepend-icon="icon"
    chips
    :multiple="multiple"
    item-text="label"
    item-value="value"
    :disabled="fieldReadonly"
    :readonly="fieldReadonly"
    :clearable="(typeof clearable === 'undefined') ? !fieldReadonly && !required : clearable"
    v-on:click:clear="value = undefined"
  >
    <v-tooltip slot="append" bottom v-if="$t(description)">
      <template v-slot:activator="{ on }">
        <v-icon slot="activator" v-on="on">mdi-help-circle</v-icon>
      </template>
      <span>{{ $t(description) }}</span>
    </v-tooltip>
  </v-select>
</template>
<script>
import _ from '@/misc/lodash';
import baseTextInput from '@/mixins/baseTextInput';

export default {
  name: 'selectField',
  mixins: [
    baseTextInput,
  ],
  props: {
    label: {
      type: String,
      default: 'fields.select',
    },
    description: {
      type: String,
      default: 'fields.selectTooltip',
    },
    default: {
      type: [String, Array],
      default: () => ([]),
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: undefined,
    },
  },
  computed: {
    items() {
      // Get translate prefix from options if is defined
      const prefix = (this.options.prefix)
        ? `${this.options.prefix}.`
        : '';

      // Compute for the select component and translate label
      return (this.options.items || []).map((item) => ({
        label: this.$t(`${prefix}${item}`),
        value: item,
      }));
    },
  },
  /**
   * Handle type for multiple value in components not in mixin
   * to prevent site effect with other components
   */
  created() {
    // Default value is handle in baseTextInput mixin
    let { value } = this;

    if (this.multiple) {
      // Normalize value if multiple is enable
      if (!Array.isArray(value)) {
        if (!_.isEmpty(value)) {
          value = [value];
        } else {
          value = [];
        }
      }
    } else if (Array.isArray(value)) {
      // Normalize value if multiple is disable and receive an array
      ([value] = value);
    }

    this.value = value;
  },
};
</script>
