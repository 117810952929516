<template>
  <v-container grid-list-xs text-xs-start>
  <v-form
      v-model="valid"
      v-if="defaultFieldsValues.length > 0"
    >
      <v-layout
          class="mt-6 mx-6"
          align-start justify-center row>
        <v-flex
          class="xs12">
          <v-card class="pa-6">
            <v-flex
              xs12
            >
              <h3>{{ $t('misc.defaultFieldsValues') }}</h3>
            </v-flex>
            <v-flex
              class="xs12">
                <select-field
                  fid="default-fields-values"
                  :description="null"
                  initial=""
                  :clearable="true"
                  label=""
                  :options="{items: defaultFieldsValues}"
                  @change="fillFields"
                />
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
    </v-form>
    <v-divider thickness="10" inset></v-divider>
    <v-form
      v-model="valid"
      v-if="organizedFields.write.length > 0"
    >
      <v-layout
          v-for="(cardRow, cr) in organizedFields.write"
          :key="cr"
          class="mt-6 mx-6"
          align-start justify-center row>
        <v-flex
          v-for="(cardCol, cc) in cardRow"
          :key="`${cr}-${cc}`"
          :class="cardCol.cw">
          <v-card class="pa-6">
            <v-flex
              xs12
              v-if="cardCol.title"
            >
              <h3>{{ $t(cardCol.title) }}</h3>
            </v-flex>
            <v-flex
              v-for="(fieldCol, fc) in cardCol"
              :key="`${cr}-${cc}-${fc}`"
              :class="fieldCol.fw">
                <Fields
                  :group-id="objectId"
                  :fields="fieldCol"
                  v-on:change="change($event, `${cr}-${cc}-${fc}`)"
                  :readonly="readonly"
                  :extLoading="loading"
                />
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
      <v-layout align-start justify-center row class="mt-2 mx-6" v-if="!readonly">
        <v-flex xs12 class="text-right">
          <v-btn
            v-on:click="cancel"
            class="white--text mr-2"
            color="secondary"
          >
            {{ $t('crud.cancel') }}
          </v-btn>
          <v-btn
            :disabled="!edited || !valid"
            :loading="loading"
            v-on:click="save"
            class="white--text"
            color="primary"
          >
            {{
              $route.params.objectId !== 'new' ?
              $t('crud.save'):
              $t('crud.create')
            }}
          </v-btn>
        </v-flex>
      </v-layout>

    </v-form>
    <template
      v-if="organizedFields.read.length > 0">
      <v-layout
        class="mt-6 mx-6"
        v-for="(cardRow, cr) in organizedFields.read"
        :key="cr"
        align-start justify-center row>
        <v-flex
          v-for="(cardCol, cc) in cardRow"
          :key="`${cr}-${cc}`"
          :class="cardCol.cw">
          <v-card class="pa-6">
            <v-flex
              xs12
              v-if="cardCol.title"
            >
              <h3>{{ $t(cardCol.title) }}</h3>
            </v-flex>
            <v-flex
              v-for="(fieldCol, fc) in cardCol"
              :key="`${cr}-${cc}-${fc}`"
              :class="fieldCol.fw">
                <Fields
                  :group-id="objectId"
                  :fields="fieldCol"
                  :readonly="true"
                />
            </v-flex>
          </v-card>
        </v-flex>
      </v-layout>
    </template>
    <slot></slot>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex';
import selectField from '@/components/crud/fields/selectField.vue';
import dataAccess from '../../misc/dataAccess';
import _ from '../../misc/lodash';

if (!Object.entries) {
  // eslint-disable-next-line func-names
  Object.entries = function (obj) {
    // eslint-disable-next-line one-var
    // eslint-disable-next-line no-var
    var ownProps = Object.keys(obj);
    // eslint-disable-next-line no-var
    var i = ownProps.length;
    // eslint-disable-next-line no-var
    var resArray = new Array(i); // preallocate the Array
    // eslint-disable-next-line no-plusplus
    while (i--) {
      resArray[i] = [ownProps[i], obj[ownProps[i]]];
    }

    return resArray;
  };
}

export default {
  components: { selectField },
  name: 'ItemManagement',
  props: {
    componentData: {
      type: Object,
    },
    overrideObjectId: {
      type: String,
      default: '',
    },
    overrideMetaInfo: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      organizedFields: {
        write: [],
        read: [],
      },
      modifiedFields: [],
      currentData: {},
      valid: false,
      loading: false,
      name: null,
      onlySwitch: false,
      fieldsByRoute: {},
      defaultFieldsCurrentOption: null, // the selected value for defautt fields
      firstInit: true,
      firstExecution: true,
    };
  },
  computed: {
    ...mapGetters('settings', ['GET_CURRENT_PAGE']),
    currentPage() {
      return (this.componentData && this.componentData.options)
        || this.$store.state.settings.currentPage;
    },
    options() {
      const { options } = this.currentPage;
      return Array.isArray(options) ? options[0] : options;
    },
    readonly() {
      return this.options.permissions.indexOf('update') === -1;
    },
    object() {
      return this.options.object;
    },
    alias() {
      return this.options.alias || this.options.object;
    },
    plugin() {
      return this.options.plugin;
    },
    objectId() {
      return (this.componentData && this.componentData.value)
        || this.$route.params.objectId
        || this.overrideObjectId;
    },
    readStatus() {
      return this.options.readStatus;
    },
    edited() {
      return this.organizedFields.write
        .some((cardRow) => (cardRow
          .some((cardCol) => (cardCol
            .some((colFields) => (colFields
              .some((field) => (field.edited))))))))
              || (this.modifiedFields.length > 0);
    },
    colClass() {
      return 'xs12';
    },
    defaultFieldsValues() {
      const defaultValues = this.options.fields.defaultFieldsValues;
      if (!defaultValues || typeof defaultValues !== 'object') {
        return [];
      }
      return Object.keys(defaultValues);
    },
  },
  watch: {
    async currentPage() {
      await this.loadItem();
    },
    '$route.path': async function w() {
      await this.loadItem();
    },
    currentData() {
      this.$emit('current-data', this.currentData);
    },
  },
  methods: {
    getDefaultValue(option) {
      return this.options.fields.defaultFieldsValues[option];
    },
    async computeName() {
      let name = this.objectId;

      if (this.options.name) {
        try {
          let foundName = name;
          const res = Object.keys(this.fieldsByRoute).some(async (key) => {
            foundName = await dataAccess.format(
              dataAccess.get(this.currentData[key], this.options.name.path),
              this.options.name.format,
            );
          });
          if (res) {
            name = foundName;
          }
        } catch (e) {
          name = this.objectId;
        }
      }
      this.name = name;
    },
    async computeFields() {
      this.currentData = this.$store.getters['crud/GET_ITEM_DATA'](this.objectId) || {};
      await this.computeName();

      const defaultDataRoute = this.alias || this.object;
      let switchFID; // old the FID (field id from app config) old the last modified field )
      let dataRoute; // old the backend route path of the current field
      if (this.onlySwitch) {
        this.organizedFields.write
          .forEach((cardRow) => (cardRow
            .forEach((cardCol) => (cardCol
              .forEach((colFields) => (colFields
                .forEach((field) => {
                  if (field.edited) {
                    switchFID = field.fid;
                    dataRoute = field.object ?? defaultDataRoute;
                    this.currentData = _.set(this.currentData[dataRoute], field.path, field.value);
                  }
                })))))));
      }

      let rawFields;
      // for legacy support I guest
      if (!Array.isArray(this.options.fields)) { // if is not an array
        // almost alway this section of the if is called because of the new config files format
        // {
        //   route: {...},
        //   options: {
        //    ...,
        //    fields: {} <== this one is alway an object in new config file format (the current one)
        //    ...,
        //   }
        // }
        rawFields = this.options.fields;
      } else {
        if (this.options.fields.length === 1 || !this.options.switch) {
          ([rawFields] = this.options.fields);
        }

        let switchValue;
        try {
          switchValue = await dataAccess.format(
            dataAccess.get(this.currentData, this.options.switch.path),
            this.options.switch.format,
          );
        // eslint-disable-next-line no-empty
        } catch (e) {}

        if (switchValue) {
          const fields = this.options.fields
            .find((fieldGroup) => (fieldGroup.switch === switchValue));

          if (fields) {
            rawFields = fields;
          }
        }

        this.onlySwitch = !rawFields;
        if (!rawFields) {
          this.onlySwitch = true;
          rawFields = {
            write: [
              this.options.switch,
            ],
            read: [],
          };
        }
      }

      // ensure that raw field alway contains a read and write field
      if (!this.onlySwitch) {
        rawFields = JSON.parse(JSON.stringify(rawFields));
        if (!rawFields.write) {
          rawFields.write = [];
        }

        if (!rawFields.read) {
          rawFields.read = [];
        }
      }
      let defaultValues;
      if (this.defaultFieldsCurrentOption) {
        defaultValues = this.getDefaultValue(this.defaultFieldsCurrentOption);
      }
      // put value and edited status into fields
      let fields = rawFields.write
        .map((edition) => {
          dataRoute = edition.object ?? defaultDataRoute;
          let value = dataAccess.get(this.currentData[dataRoute], edition.path);

          // if user tryed to fill field with default values
          if (this.defaultFieldsCurrentOption && defaultValues[edition.fid]) {
            value = defaultValues[edition.fid];
          }
          if (this.objectId === 'new' && edition.default && !value && value !== false) {
            value = edition.default;
          }

          if (edition.component === 'autocompleteField' || edition.component === 'selectField') {
            if (typeof value !== 'undefined') {
              if (!Array.isArray(value) && edition.multiple) {
                value = [value];
              }
            } else {
              value = [];
            }
          }
          let edited = switchFID === edition.fid
              || (this.objectId === 'new' && value === edition.default)
              || (this.defaultFieldsCurrentOption && defaultValues[edition.fid]);
          if (this.firstExecution) {
            edited = false;
            if (edition.path === 'remaining_cable_length') {
              value = null;
            }
          }

          if (edited) {
            this.modifiedFields.push(edition.fid);
          }
          return {
            value,
            initial: value,
            edited,
            ...edition,
          };
        });

      // set the correct format for readonly data data
      for (let index = 0; index < fields.length; index += 1) {
        if (fields[index].format && fields[index].readonly) {
          // eslint-disable-next-line no-await-in-loop
          fields[index].value = await dataAccess.format(
            fields[index].value,
            fields[index].format,
          );
        }
      }
      const writeFields = [];

      fields.forEach((field) => {
        const coordinates = field.coordinates || {
          cr: 0,
          cc: 0,
          cw: this.colClass,
          fc: 0,
          fw: 'xs12',
        };

        if (!writeFields[coordinates.cr]) {
          writeFields[coordinates.cr] = [];
        }
        if (!writeFields[coordinates.cr][coordinates.cc]) {
          writeFields[coordinates.cr][coordinates.cc] = [];
          writeFields[coordinates.cr][coordinates.cc].cw = coordinates.cw || 'xs12';
          writeFields[coordinates.cr][coordinates.cc].title = coordinates.title || '';
        }
        if (!writeFields[coordinates.cr][coordinates.cc][coordinates.fc]) {
          writeFields[coordinates.cr][coordinates.cc][coordinates.fc] = [];
          writeFields[coordinates.cr][coordinates.cc][coordinates.fc]
            .fw = coordinates.fw || 'xs12';
        }

        if (typeof coordinates.fr === 'undefined') {
          coordinates.fr = writeFields[coordinates.cr][coordinates.cc][coordinates.fc].length;
        }

        writeFields[coordinates.cr][coordinates.cc][coordinates.fc][coordinates.fr] = field;
      });

      fields = rawFields.read
        .map((read) => {
          dataRoute = read.object ?? defaultDataRoute;
          if (read.path) {
            let value = dataAccess.get(this.currentData[dataRoute], read.path);

            if (!value && value !== false) {
              return false;
            }

            if (read.component === 'autocompleteField' || read.component === 'selectField') {
              if (typeof value !== 'undefined') {
                if (!Array.isArray(value) && read.multiple) {
                  value = [value];
                }
              } else {
                value = [];
              }
            }

            return {
              value, initial: value, edited: false, ...read,
            };
          }

          if (read.component === 'layoutField') {
            const value = this.currentData[dataRoute];

            return {
              value, edited: false, ...read,
            };
          }
          return read;
        }).filter((read) => (read !== false));

      for (let index = 0; index < fields.length; index += 1) {
        if (fields[index].format) {
          // eslint-disable-next-line no-await-in-loop
          fields[index].value = await dataAccess.format(
            fields[index].value,
            fields[index].format,
          );
        }
      }

      fields.sort((fA, fB) => {
        const coordinatesA = fA.coordinates || {
          cr: 0,
          cc: 0,
          cw: this.colClass,
          fc: 0,
          fw: 'xs12',
        };
        const coordinatesB = fB.coordinates || {
          cr: 0,
          cc: 0,
          cw: this.colClass,
          fc: 0,
          fw: 'xs12',
        };

        if (coordinatesA.cr < coordinatesB.cr) {
          return -1;
        }
        if (coordinatesA.cr > coordinatesB.cr) {
          return 1;
        }
        if (coordinatesA.cc < coordinatesB.cc) {
          return -1;
        }
        if (coordinatesA.cc > coordinatesB.cc) {
          return 1;
        }
        if (coordinatesA.fc < coordinatesB.fc) {
          return -1;
        }
        if (coordinatesA.fc > coordinatesB.fc) {
          return 1;
        }
        if (!coordinatesA.fr) {
          return 1;
        }
        if (!coordinatesB.fr) {
          return -1;
        }
        if (coordinatesA.fr < coordinatesB.fr) {
          return -1;
        }
        if (coordinatesA.fr > coordinatesB.fr) {
          return 1;
        }

        return 0;
      });

      const readFields = [];

      fields.forEach((field) => {
        const coordinates = field.coordinates || {
          cr: 0,
          cc: 0,
          cw: this.colClass,
          fc: 0,
          fw: 'xs12',
        };

        if (!readFields[coordinates.cr]) {
          readFields[coordinates.cr] = [];
        }
        if (!readFields[coordinates.cr][coordinates.cc]) {
          readFields[coordinates.cr][coordinates.cc] = [];
          readFields[coordinates.cr][coordinates.cc].cw = coordinates.cw || 'xs12';
        }
        if (!readFields[coordinates.cr][coordinates.cc][coordinates.fc]) {
          readFields[coordinates.cr][coordinates.cc][coordinates.fc] = [];
          readFields[coordinates.cr][coordinates.cc][coordinates.fc]
            .fw = coordinates.fw || 'xs12';
        }

        if (typeof coordinates.fr === 'undefined') {
          coordinates.fr = readFields[coordinates.cr][coordinates.cc][coordinates.fc].length;
        }

        readFields[coordinates.cr][coordinates.cc][coordinates.fc][coordinates.fr] = field;
      });

      this.organizedFields = {
        write: writeFields,
        read: readFields,
      };
      if (this.firstExecution) {
        this.firstExecution = false;
      }
    },
    async cancel() {
      if (!this.componentData) {
        this.$router.go(-1);
      }
      await this.computeFields();
    },
    async change(fields, path) {
      _.set(this.organizedFields, path, fields);
      if (this.onlySwitch) {
        await this.computeFields();
      }
    },
    async save() {
      const currentData = { ...this.currentData };
      const defaultDataRoute = this.alias || this.object;
      let dataRoute;
      const editedFields = {};
      this.organizedFields.write
        .forEach((cardRow) => (cardRow
          .forEach((cardCol) => (cardCol
            .forEach((colFields) => (colFields
              .forEach((field) => {
                if (field.edited
                || (this.modifiedFields.includes(field.fid))) {
                  dataRoute = field.object ?? defaultDataRoute;
                  if (editedFields[dataRoute]) {
                    editedFields[dataRoute].push(field.path);
                  } else {
                    editedFields[dataRoute] = [field.path];
                  }
                  // TODO check for addition
                  currentData[dataRoute] = _.set(
                    currentData[dataRoute],
                    field.writePath || field.path,
                    field.value,
                    // !field.addition, // legacy function
                  );
                }
              })))))));
      this.modifiedFields = [];
      this.currentData = currentData;
      if (!_.isEmpty(this.currentData)) {
        this.loading = true;
        this.$nextTick(async () => {
          if (this.objectId === 'new') {
            const result = await this.$store
              .dispatch('crud/CREATE', {
                object: this.object,
                alias: this.alias,
                body: this.currentData,
                params: this.options.queryParams,
              });
            if (result !== false) {
              this.$nextTick(() => {
                setTimeout(() => {
                  if (this.options.itemRoute) {
                    this.$router.push(`${this.options.itemRoute}/${result.id}`);
                  } else {
                    this.$router.push(`/item/${this.object}/${result.id}`);
                  }
                }, 1000);
              });
            }
          } else {
            Object.entries(editedFields).forEach(async ([itemRoute, fieldsPath]) => {
              const data = {};
              fieldsPath.forEach((path) => {
                data[path] = this.currentData[itemRoute][path];
              });
              await this.$store
                .dispatch('crud/UPDATE', {
                  object: itemRoute,
                  alias: this.alias,
                  objectId: this.objectId,
                  body: data,
                  params: this.options.queryParams,
                });
            });
            // TODO Dirt fix need found better solution, maybe update data in store and don't search
            // * Need delay before re computed fields due to delay of kuzzle to update data
            this.$nextTick(() => {
              setTimeout(() => {
                this.computeFields();
              }, 1000);
            });
          }
          setTimeout(() => {
            // we put a timeout in order for the event to be visible
            this.loading = false;
          }, 1000);
        });
      }
    },
    async loadItem() {
      if (this.objectId !== 'new') {
        const res = [];
        Object.keys(this.fieldsByRoute).forEach((objectRoute) => {
          const result = this.$store.dispatch('crud/READ_ITEM', {
            object: objectRoute,
            alias: this.alias,
            objectId: this.objectId,
            params: this.options.queryParams,
          });
          res.push(result);
        });
        await Promise.all(res);
      }
      await this.computeFields();
    },
    async fillFields(selectedOption) {
      this.defaultFieldsCurrentOption = selectedOption;
      await this.computeFields();
      this.defaultFieldsCurrentOption = null;
      const a = { ...this.organizedFields };
      this.$set(this.organizedFields, 'write', []);
      this.$set(this.organizedFields, 'read', []);
      // force the vue to update fields component
      this.$nextTick(() => {
        this.$set(this.organizedFields, 'write', a.write);
        this.$set(this.organizedFields, 'read', a.read);
      });
    },
  },
  async mounted() {
    this.options?.fields?.write.forEach?.(async (field) => {
      if (field.object) {
        const list = this.fieldsByRoute?.[field.object] ?? [];
        this.fieldsByRoute[field.object] = [...list, field];
      } else {
        const list = this.fieldsByRoute?.[this.object] ?? [];
        this.fieldsByRoute[this.object] = [...list, field];
      }
    });
    await this.loadItem();
  },
  metaInfo() {
    this.$store.commit('settings/SET_CURRENT_TITLE', '');
    if (!this.componentData) {
      let title = '';
      if (this.objectId !== 'new' && !this.overrideMetaInfo && this.name) {
        title = `: ${this.name}`;
      } else if (this.objectId !== 'new' && this.overrideMetaInfo) {
        title = `${this.overrideMetaInfo}`;
      } else if (this.objectId === 'new') {
        title = `: ${this.$t('misc.create')}`;
      }
      this.$store.commit('settings/SET_CURRENT_TITLE', title.trim());
      return {
        title: title.trim(),
      };
    }
    return null;
  },
  beforeDestroy() {
    this.$store.commit('settings/SET_CURRENT_TITLE', '');
  },
};
</script>
